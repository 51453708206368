import request from '@/utils/request'

// 查询客户提现记录
export function findAgentWithdrawals(params) {
  return request({
    url: `/agent_withdrawals`,
    method: 'get',
    params: params
  })
}

// 记录客户提现
export function createAgentWithdrawal(data) {
  return request({
    url: `/agent_withdrawals`,
    method: 'post',
    data
  })
}

// 支付方式选项数据
export function findWithdrawalPayWayOptions() {
  return request({
    url: `/withdrawals/pay_way/options`,
    method: 'get'
  })
}

// 提现类型选项数据
export function findWithdrawalTypeOptions() {
  return [
    { label: '线上提现', value: 'online_deposit' },
    { label: '线下提现', value: 'offline_deposit' }
  ]
}

// 导出提现记录
export function exportAgentWithdrawals(data) {
  return request({
    url: `/agent_withdrawals/export`,
    method: 'post',
    data
  })
}
